export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [~4],
		"/contact": [~5],
		"/problems": [~6],
		"/problems/[slug]": [~7],
		"/user-guide": [~8,[2]],
		"/user-guide/(pages)/bibliography": [~10,[2,3]],
		"/user-guide/(pages)/heuristics": [~11,[2,3]],
		"/user-guide/(pages)/sds-by-number": [~12,[2,3]],
		"/user-guide/(pages)/sds-by-topic": [~13,[2,3]],
		"/user-guide/(pages)/[slug]": [~9,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';